.buttonblock{
    display:flex;
    .button{
        height:50px;
        flex:1;
        padding:0 15px;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius:25px;
        cursor: pointer;
    }
    .selected{
        background-image: linear-gradient(#52977F, #277E6C);
        color:white;
    }
    .no{

    }
}
