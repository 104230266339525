@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
*{
font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue;
  /*background: #FAFAFA;*/
  /*background:url(./assets/bgmax.jpg);*/
  background-size:cover;
}


input:focus {
  border-color: #78caff;
  outline: 0;
  box-shadow: none !important;
}

.centerall{
    display:flex;
    align-items:center;
    justify-content: center;
}

.grisradius{
  border: 1px solid gainsboro;
  border-radius: 5
}

.finalizaActivas .rt-td{
  margin: auto;
}
.footerContainer {
  height: 60px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#52977F), to(#277E6C));
  background-image: linear-gradient(#52977F, #277E6C);
  box-shadow: 0px -1px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center; }

.button {
  height: 30px;
  min-width: 150px;
  position: relative;
  border-radius: 3px;
  margin-right: 18px;
  line-height: 30px !important;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px !important;
  -webkit-font-smoothing: antialiased;
  /* > i {
    margin-right: 13px;
    font-weight: 600;
    padding-top: 1px;
  } */
}

.button span {
  line-height: 30px;
  font-size: 18px;
}

.button:focus {
  outline: 0;
}

.button:disabled {
  cursor: not-allowed;
}

.button:hover::after {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 3px;
  content: '';
  opacity: 0.1;
  background: #FFFFFF;
}

.primary {
  background: linear-gradient(315deg, #0097F6 0%, #005EEA 100%);
  color: white;
}

.primary:active {
   box-shadow: inset 1px 1px 3px rgba(0,0,0,.15);
}

.github {
  background: #000;
  color: white;
}

.google {
  border: 1px solid #d6d9dc;
  color: #535a60;
}

.twitter {
  background:  #00aced;
  color: #fff;
}

.inputDescriptionContainer {
  width: 200%;
  margin-top: 13px;
  line-height: 12px;

  > small {
    color: #9EA7B8;
    font-size: 12px;
  }
}

.errorContainer {
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 13px;
  font-size: 13px;
}

.inputCheckbox {
  margin-bottom: 0;
  font-weight: 400;
}

.disabled {
  cursor: not-allowed;
}

.labelCheckbox {
  /* margin-left: 12px; */
  cursor: pointer;
}

.inputCheckboxContainer {
  margin-bottom: 15px;
  font-size: 13px;
}

.spacer {
  height: 5px;
}

.inputCheckboxDescriptionContainer {
  width: 150%;
  margin-top: 2px;
  padding-left: 25px;
  line-height: 12px;

  small {
    color: #9EA7B8;
    font-size: 12px;
  }
}

.inputTitle {
  margin-bottom: 17px;
  font-weight: 500;
}

.label {
  margin-bottom: 0;
  font-weight: 500;
}

.addonEmail {
 width: 34px;
 height: 34px;
 margin-top: 9px;
 padding-left: 9px;
 background-color: rgba(16, 22, 34, 0.02);
 border: 1px solid #E3E9F3;
 border-right: 0;
 border-radius: 2.5px;
 color: rgba(16, 22, 34, 0.5);
 line-height: 32px;
 font-size: 13px;
 font-weight: 600!important;
 text-transform: capitalize;
 -moz-appearance: none;
 -webkit-appearance: none;
 -webkit-font-smoothing: antialiased;
}

.addonEmail::after {
  content: '@';
  display: inline-table;
  color: #B3B5B9;
  font-size: 16px;
  font-weight: 900;
}

.inputEmailContainer {
  min-width: 200px;
  margin-bottom: 10px;
  font-size: 13px;
  display: flex;
}

.errorAddon {
  border: 1px solid #ff203c!important;
  border-right: none!important;
}

.addonFocus {
  border-color: #78caff;
  border-right: 0;
}

.invalidEmail {
  border-color: #ff203c !important;
  border-left: 0;
}

.inputEmail {
  height: 34px;
  margin-top: 9px;
  padding-left: 10px;
  background-size: 0 !important;
  border: 1px solid #E3E9F3;
  border-left: 0px !important;
  border-radius: 2.5px;
  line-height: 34px;
  font-size: 13px;
  box-shadow: 0px 1px 1px rgba(104, 118, 142, 0.05);
}

.inputEmail:focus {
  border-color: #78caff;
  outline: 0;
  box-shadow: none !important;
}

.inputEmailWithErrors {
  min-width: 200px;
  margin-bottom: 15px;
  font-size: 13px;
  text-align: start;
}

.spacer {
  height: 5px;
}

.arrowContainer {
  height: 32px;
  width: 28px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  z-index: 99; }

.arrowRight {
  position: absolute;
  top: 56px;
  right: 0; }
  .arrowRight:before {
    content: '\f054';
    vertical-align: middle;
    text-align: center;
    font-family: 'FontAwesome';
    font-size: 20px;
    font-weight: 800; }

.arrowLeft {
  position: absolute;
  top: 56px;
  left: 0; }
  .arrowLeft:before {
    content: '\f053';
    vertical-align: middle;
    text-align: center;
    font-family: 'FontAwesome';
    font-size: 20px;
    font-weight: 800; }

.imgPreviewHint {
  display: block;
  width: 100%;
  position: absolute;
  top: 49px;
  padding: 12px 75px 0 75px;
  white-space: pre-line;
  color: #333740;
  line-height: 18px;
  font-size: 13px; }
  .imgPreviewHint > span {
    display: block; }
    .imgPreviewHint > span > u {
      cursor: pointer; }

.icon {
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: middle; }

.imgPreviewContainer {
  height: 162px;
  width: 100%;
  position: relative;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  text-align: center;
  vertical-align: middle;
  background-color: #333740;
  background-position: center;
  background-repeat: no-repeat !important;
  white-space: nowrap;
  z-index: 1; }
  .imgPreviewContainer > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    max-width: 100%;
    z-index: 3; }

.iconContainer {
  position: absolute;
  top: 5px;
  right: 3px;
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 11px;
  cursor: pointer; }

.hintDropping {
  display: block;
  position: absolute;
  top: 37px;
  z-index: 9999;
  padding: 12px 40px 0 40px;
  line-height: 18px;
  color: #fff !important;
  font-size: 13px;
  white-space: pre-line; }
  .hintDropping > span {
    display: block;
    padding: 12px 40px 0 40px;
    color: #fff; }
    .hintDropping > span > u {
      cursor: pointer; }

.fileIcon {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #fff;
  justify-content: space-around;
  font-size: 30px; }

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 162px;
  z-index: 999;
  background: #333740;
  opacity: 0.9; }

.detailBanner {
  display: flex;
  justify-content: space-between;
  line-height: 23px;
  -webkit-font-smoothing: antialiased; }
  .detailBanner > div:first-child {
    display: flex; }
    .detailBanner > div:first-child > div:nth-child(2) {
      color: #333740;
      font-size: 13px !important;
      font-weight: 400; }

.chevronDown {
  margin-right: 8px; }
  .chevronDown:before {
    content: '\F078';
    display: block;
    color: #787E8F;
    font-family: 'FontAwesome';
    font-size: 11px !important; }

.chevronRight {
  margin-right: 8px; }
  .chevronRight:before {
    content: '\f054';
    display: block;
    color: #787E8F;
    font-family: 'FontAwesome';
    font-size: 11px !important; }

.infoContainer {
  border-top: 1px solid #F6F6F6;
  padding-top: 7px; }

.infoWrapper {
  line-height: 18px;
  font-size: 13px !important; }
  .infoWrapper > span:first-child {
    color: #333740;
    font-weight: 500; }
  .infoWrapper > span:last-child {
    color: rgba(51, 55, 64, 0.8);
    font-weight: 400; }

.inputFileDetails {
  width: 100%;
  padding-top: 4px;
  margin-bottom: -13px;
  margin-top: -8px; }

.positionContainer {
  margin-left: 10px;
  color: #333740;
  font-size: 13px !important;
  -webkit-font-smoothing: antialiased; }
  .positionContainer > span:first-child {
    font-size: 13px !important;
    font-weight: 600; }
  .positionContainer > span:last-child {
    font-size: 12px;
    font-weight: 400; }

.removeContainer {
  color: #FF3000;
  font-size: 13px !important;
  font-weight: 400;
  cursor: pointer; }

.externalLink {
  color: #333740;
  text-decoration: none; }
  .externalLink:hover, .externalLink:active {
    color: #333740;
    text-decoration: none; }
  .externalLink > i, .externalLink svg {
    margin-right: 7px;
    color: #B3B5B9; }

.inputFile {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.inputFileButtonContainer {
  width: 100%;
  height: 34px;
  text-align: center;
  background-color: #FAFAFB;
  border: 1px solid #E3E9F3;
  border-top: 0;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  color: #333740;
  font-size: 12px !important;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 35px;
  cursor: pointer;
  text-transform: uppercase; }
  .inputFileButtonContainer > i {
    margin-right: 10px; }

.copy {
  cursor: copy !important; }

.inputFileWithErrorsContainer {
  min-width: 200px;
  margin-bottom: 18px;
  margin-top: -2px;
  font-size: 13px; }

.labelFile {
  margin-bottom: 9px; }

.labelNumber {
  font-size: 13px !important;
  font-weight: 500; }

.labelContainer {
  width: 100%;
  text-align: left; }

.inputPassword {
  height: 34px;
  margin-top: 9px;
  padding-left: 10px;
  background-size: 0 !important;
  border: 1px solid #E3E9F3;
  border-radius: 2.5px;
  line-height: 34px;
  font-size: 13px;
  box-shadow: 0px 1px 1px rgba(104, 118, 142, 0.05);
}

.iconEyeSubWrapper {
  position: absolute;
  top: -26px;
  right: 27px;
  color: #9EA7B8;
}

.iconEyeSubWrapper:hover {
  color: black!important;
}


.iconEyeWrapper {
  position: relative;
}

.inputPasswordWithErrors {
  min-width: 200px;
  margin-bottom: 15px;
  font-size: 13px;
  text-align: start;
}

.inputText {
  height: 34px;
  margin-top: 9px;
  padding-left: 10px;
  background-size: 0 !important;
  border: 1px solid #E3E9F3;
  border-radius: 2.5px;
  line-height: 34px;
  font-size: 13px;
  box-shadow: 0px 1px 1px rgba(104, 118, 142, 0.05);
}
.inputTextWithErrors {
  min-width: 200px;
  margin-bottom: 15px;
  font-size: 13px;
  text-align: start;
}

.spacer {
  height: 5px;
}

.link, button {
  margin-bottom: 5px;
}

.authPage {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 0px 0;
  background: #FAFAFB;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
}

.wrapper {
  height: 221px;
  width: 685px;
  text-align: center;
  /* background-image: url('../../images/background_empty.svg'); */
  background-position-x: center;
  font-size: 14px;
}

.errorsContainer {
  margin-top: -21px;
  margin-bottom: 18px;
  color: #ff203c;
}

.headerContainer {
  line-height: 36px;
  font-size: 24px;
  font-weight: 600;
}

.headerContainer img {
  /*margin-top: 1px;*/
  height: 40px;
}
.headerDescription {
  width: 416px;
  text-align: center;
  margin: auto;
  padding: 13px 30px 0 30px;
  line-height: 18px;
  color: #333740;
  font-size: 14px;
}

.formContainer {
  min-height: 200px;
  width: 416px;
  margin: 14px auto;
  margin-bottom: 0;
  padding: 33px 15px 15px 15px;
  border-radius: 2px;
  background-color: #FFFFFF;
  border-radius:20px;
  box-shadow: 0 2px 20px rgba(0,0,0,0.2)
}

.formContainer input{
  width: calc(100% - 10px);
}

.loginButton {
  margin-top: -6px;
  padding-right: 0;
  text-align: right;
}

.loginButton, button {
  margin-right: 16px;
}

.buttonContainer {
  padding-top: 3px;
}

/* .linkContainer {
  padding-top: 18px;
   > a {
    color: #262931;
    font-size: 13px;
    &:hover, &:active, &:focus {
      text-decoration: none;
      outline: 0;
    }
  }
} */

.linkContainer, a {
  color: #262931;
  font-size: 13px;
}

.logoContainer {
  position: absolute;
  left: 30px; bottom: 30px;
}

.logoContainer img {
  height: 34px;
}

.separator {
  width: 100%;
  height: 2px;
  margin: 10px 0;
  background-color: rgba(14, 22, 34, 0.04);
}
.accede {
 background-color: #088A68;
 background-image: -webkit-gradient(linear, right top, left top, from(#088A68), to(#D8F6CE));
 background-image: linear-gradient(-90deg, #088A68, #D8F6CE);
 color: white;
 border-radius: 15px;
 border-style: none;
 height: 3.5em;
}
.identi {
 color: #000000;
 font-family: sans-serif;
 font-weight: bold;
}
.userimg {
  height: 300%;
  
}
.sans {
  font-family: sans-serif;
}
.textoacceso {
  font-family: sans-serif;
  text-align: center;
  font-size: 0.9rem;
}
.sombra {
  box-shadow: 0 8px 10px 0 #b4cbf1;
}

/*body{background: url(../../assets/images/bg.jpg) cover;}*/
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.head{
  background: white;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.3);
}

.dropzoneCleaner > div{
  height: auto !important;
  border: none !important;
  margin: 0 auto;
}

.rst__rowContents {
  border: 0 !important;
}
  
.rst__rowContentsDragDisabled {
  border: 0 !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  padding: 0 !important;
  min-width: 0 !important;
  background-color: transparent !important;
  align-items: flex-end !important;
}
.bgfiltro {
  height: 80px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#52977F), to(#277E6C));
  background-image: linear-gradient(#52977F, #277E6C);
  background-size: cover;
  background-position: center;
  color: white; }
  .bgfiltro h1 {
    margin: 0px;
    text-align: left;
    padding-left: 50px; }

.innerFiltro {
  display: flex;
  justify-content: space-between; }

.containerFiltro {
  display: flex; }

.tituloblock {
  padding: 15px; }

.fechablock {
  flex: 3 1;
  padding: 15px;
  justify-content: flex-end;
  display: flex; }
  .fechablock .DateRangePickerInput__withBorder {
    min-width: 310px; }

.centroblock {
  flex: 2 1;
  padding: 15px; }
  .centroblock .Dropdown-root {
    height: 50px; }
  .centroblock .Dropdown-control {
    height: 50px; }
  .centroblock .Dropdown-placeholder {
    line-height: 30px; }
  .centroblock .Dropdown-arrow {
    top: 23px; }
  .centroblock .Dropdown-option {
    padding: 15px 10px; }

.breadcrumbs span {
  white-space: nowrap; }

.CircularProgressbar-path {
  stroke: red; }

.CircularProgressbar-trail {
  stroke: #f3f3f3; }

.CircularProgressbar-text {
  fill: yellow; }

.CircularProgressbar-background {
  fill: green; }

.card {
  background: white;
  border-radius: 20px;
  box-shadow: 0 5px 10px 2px rgba(200, 200, 200, 0.3); }

.titleblock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-height: 2em;
  margin: 0px auto;
  padding: 10px 0; }
  .titleblock svg {
    margin-right: 10px;
    font-size: 25px;
    color: gray;
    min-width: 25px; }
  .titleblock h4 {
    font-size: 12px; }

.progresscontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0px; }

.chartblock {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px; }

svg.recharts-surface tspan {
  font-size: 0.5rem !important;
  color: black !important;
  font-family: Arial; }

.buttonblock {
  display: flex; }
  .buttonblock .button {
    height: 50px;
    flex: 1 1;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    cursor: pointer; }
  .buttonblock .selected {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#52977F), to(#277E6C));
    background-image: linear-gradient(#52977F, #277E6C);
    color: white; }

.rankingContainer {
  overflow: auto; }

.rankingElement {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid gainsboro;
  height: 70px; }
  .rankingElement .itemImage {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex: 1.5 1;
    padding: 0 10px; }
    .rankingElement .itemImage img {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      object-fit: cover;
      object-position: center; }
  .rankingElement .itemTitle {
    flex: 4 1;
    display: flex;
    padding: 0 15px;
    text-align: left; }
  .rankingElement .itemBar {
    flex: 9 1;
    align-items: center;
    justify-content: center; }
  .rankingElement .itemValue {
    flex: 1.5 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px; }
    .rankingElement .itemValue span {
      font-size: 2.2rem;
      font-weight: 800; }

.rankingElement:nth-child(even) {
  background-color: #f3f3f3; }

.rankingElement:last-child {
  border-bottom: 0px solid gainsboro; }

.imgtopcard {
  width: 100%;
  height: 170px;
  background: gainsboro; }

.detailsbotcard .name {
  padding: 10px 0px 0px 10px;
  width: 100%;
  display: block;
  text-align: left;
  font-size: 24px; }

.detailsbotcard .location {
  padding: 0px 0px 0px 10px;
  width: 100%;
  display: block;
  text-align: left;
  font-size: 16px;
  color: gainsboro; }

.butbottom {
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .butbottom .settings {
    border: 1px solid gainsboro;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .butbottom .settings:hover {
    color: #0380b8; }

.barblock {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center; }

.barblock {
  padding: 10px; }

.outherspeed {
  margin-top: 30px; }

.indicator {
  width: 90%;
  margin: 20px 0px;
  height: 70px;
  display: flex;
  align-items: center;
  margin-left: 5%; }
  .indicator h5 {
    margin-bottom: 1em; }

.BarWrapper {
  width: 90%;
  padding: 20px 0px 30px 0px;
  margin: 0px 5%;
  position: relative; }

.barcontainer {
  height: 20px;
  background: #D8D8D8;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 1s;
  transition: all 1s; }
  .barcontainer .barindicator {
    height: 100%;
    position: absolute;
    z-index: 1;
    border-radius: 20px;
    -webkit-transition: all 1s;
    transition: all 1s;
    -webkit-transition-delay: .5s;
            transition-delay: .5s;
    background: gray; }
  .barcontainer .barobjective {
    height: 100%;
    position: absolute;
    z-index: 3;
    opacity: 0.25;
    -webkit-filter: brightness(0.65) saturate(1.5);
            filter: brightness(0.65) saturate(1.5);
    border-radius: 20px;
    -webkit-transition-delay: .3s;
            transition-delay: .3s;
    -webkit-transition: all 1s;
    transition: all 1s;
    background: gray; }
  .barcontainer .green {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#B4EC51), to(#429321));
    background-image: linear-gradient(#B4EC51 0%, #429321 100%); }
  .barcontainer .red {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#F5515F), to(#9F041B));
    background-image: linear-gradient(#F5515F 0%, #9F041B 100%); }
  .barcontainer .orange {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#FAD961), to(#F76B1C));
    background-image: linear-gradient(#FAD961 0%, #F76B1C 100%); }

.bararrow {
  display: block;
  width: 50px;
  height: 30px;
  font-size: 10px;
  line-height: 0px;
  position: absolute;
  opacity: 0.8;
  margin-left: -25px;
  -webkit-transition: all 2s;
  transition: all 2s;
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s; }

.objetiv {
  top: 0px;
  opacity: 0.4; }

.etiqetainterior {
  color: white;
  position: absolute;
  z-index: 3;
  font-size: 10px;
  line-height: 15px;
  left: 10px;
  width: 90%;
  height: 100%; }

.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1257890;
  background: white;
  -webkit-transition: all 600ms;
  transition: all 600ms;
  opacity: 0.99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; }

.loader div, .loader span {
  margin: 0 3px 0 5px;
  -webkit-animation-name: punto;
          animation-name: punto;
  -webkit-animation-duration: 2000ms;
          animation-duration: 2000ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  color: white;
  text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15); }

.loader h1 {
  color: white;
  text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15); }

.loader:not(.loading) {
  opacity: 0;
  z-index: -1;
  overflow: visible;
  overflow: initial; }

#punto1 {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms; }

#punto2 {
  -webkit-animation-delay: 500ms;
          animation-delay: 500ms; }

#punto3 {
  -webkit-animation-delay: 1000ms;
          animation-delay: 1000ms; }

@-webkit-keyframes punto {
  0% {
    opacity: 0;
    margin-top: 5px; }
  50% {
    opacity: 1;
    margin-top: -5px; }
  100% {
    opacity: 0;
    margin-top: 5px; } }

@keyframes punto {
  0% {
    opacity: 0;
    margin-top: 5px; }
  50% {
    opacity: 1;
    margin-top: -5px; }
  100% {
    opacity: 0;
    margin-top: 5px; } }

.fichatitleblock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 200px;
  position: relative; }
  .fichatitleblock h3 {
    font-size: 25px;
    margin: 0px 0px;
    color: #5A5970;
    margin-top: -10px; }
  .fichatitleblock small {
    font-size: 16px;
    color: gainsboro; }
  .fichatitleblock h5 {
    font-size: 20px;
    color: #ABA9B1;
    margin: 0px 0px; }
  .fichatitleblock .btnActionWrapper {
    display: flex;
    position: absolute;
    bottom: -25px;
    width: 100%;
    justify-content: space-evenly; }
  .fichatitleblock .botonficha {
    height: 45px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: gray;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    color: white;
    cursor: pointer;
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
  .fichatitleblock .botonficha:hover {
    background-color: #5A5970;
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }

.fichapictureblock {
  height: 300px;
  width: 100%;
  border-radius: 25px 25px 0px 0px;
  overflow: hidden; }
  .fichapictureblock img {
    object-fit: cover;
    object-position: center; }

.indicator {
  position: absolute;
  top: -30px;
  right: 0px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center; }

.red {
  background-color: red; }

.green {
  background-color: green; }

.mainBlock {
  height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2em; }
  .mainBlock .imageTeam {
    width: 100%;
    height: 150px;
    overflow: hidden;
    display: flex;
    border-radius: 25px 25px 0px 0px; }
    .mainBlock .imageTeam img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .mainBlock .teamBlock {
    padding: 0 15px;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .mainBlock .teamBlock h3 {
      margin-bottom: 0px;
      text-decoration: none; }
    .mainBlock .teamBlock h5 {
      margin-top: 0px;
      margin-bottom: 0px;
      text-decoration: none;
      color: #88949c; }
  .mainBlock .indicadoresBlock {
    padding: 0 15px;
    margin-bottom: 10px; }
  .mainBlock .buttonBlock {
    display: flex;
    border-top: 2px solid gainsboro; }
    .mainBlock .buttonBlock .buttoncard {
      flex: 1 1;
      height: 50px;
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 25px;
      color: gray;
      -webkit-transition: 0.3s all;
      transition: 0.3s all;
      cursor: pointer; }
    .mainBlock .buttonBlock .buttoncard:hover {
      color: black;
      -webkit-transition: 0.3s all;
      transition: 0.3s all; }
    .mainBlock .buttonBlock .buttoncard:first-child {
      border-right: 2px solid gainsboro; }

.iconplot {
  background-image: -webkit-gradient(linear, right top, left top, from(#C0D9B2), to(#04685C));
  background-image: linear-gradient(-90deg, #C0D9B2 0%, #04685C 100%);
  box-shadow: 0 10px 20px 0 rgba(74, 74, 74, 0.26);
  border-radius: 3px;
  max-width: 45px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center; }

.radius {
  border-radius: 18px; }

.table {
  width: 100%;
  margin-bottom: 0; }

.rt-th {
  border: 0px !important;
  font-weight: 700;
  color: gray; }

.bottonera {
  display: flex;
  flex-direction: row;
  border: 1px solid #DEE1E6;
  border-radius: 100px;
  height: 45px;
  width: auto;
  align-items: center;
  justify-content: space-evenly; }

.boton {
  font-size: 12px;
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.buticon {
  cursor: pointer; }

.buticon:hover {
  opacity: 0.8; }

.activo {
  background-image: -webkit-gradient(linear, right top, left top, from(#C0D9B2), to(#04685C));
  background-image: linear-gradient(-90deg, #C0D9B2 0%, #04685C 100%);
  box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.16);
  border-radius: 100px;
  padding: 10px 10px;
  color: white;
  font-weight: 700;
  -webkit-transition: -webkit-filter 300ms;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
  cursor: default; }

.activo:hover {
  -webkit-filter: brightness(0.9) saturate(1.1);
          filter: brightness(0.9) saturate(1.1); }

.table {
  overflow: hidden;
  border-radius: 19px; }

.ReactTable .rt-thead .rt-resizable-header {
  overflow: hidden;
  height: 55px;
  justify-content: center;
  align-items: center;
  display: flex; }

.ReactTable .rt-tbody .rt-tr-group {
  height: 65px; }

.cabecera {
  display: flex;
  flex-direction: row;
  height: 75px;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid gainsboro; }
  .cabecera .comp {
    color: gainsboro;
    font-weight: 700;
    font-size: 16px; }

.megarow {
  padding-right: 10px;
  height: 70px;
  border-bottom: 1px solid gainsboro;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.megarow:nth-child(even) {
  background-color: #F2F2F2; }

.avatar {
  width: 50px !important;
  height: 50px !important; }

.avatarbig {
  width: 70px !important;
  height: 70px !important; }

.comp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  font-size: 12px;
  overflow: hidden;
  text-align: center; }

.meganum {
  font-size: 40px;
  font-weight: 700;
  color: gray; }

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4; }

.leftpad p {
  margin-bottom: 0; }

.navbar-brand img {
  width: 150px;
  height: auto; }

.nombreavatar {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .nombreavatar h5 {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 24px; }
  .nombreavatar p {
    margin-bottom: 0px; }

.Modal {
  border-radius: 24px;
  width: 80%;
  height: 500px;
  background-color: white;
  z-index: 5;
  overflow-Y: overlay; }

.inputer {
  /* Rectangle 8 Copy 20: */
  background: #FFFFFF;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.23);
  border-radius: 7px;
  height: 45px;
  width: 100%;
  border: 0px;
  padding: 0px 10px; }

input.inputer:focus {
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.23); }

.selecthor {
  background: #FFFFFF !important;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.23) !important;
  border-radius: 7px !important;
  height: 45px !important; }

.css-1aya2g8 {
  height: 45px;
  border: 0px !important; }

.css-1rtrksz {
  border: 0px !important;
  height: 45px !important; }

.nobut {
  opacity: 0.58;
  background-image: -webkit-gradient(linear, right top, left top, from(#E5E5E5), to(#939393));
  background-image: linear-gradient(-90deg, #E5E5E5 0%, #939393 100%);
  box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.16);
  border-radius: 100px;
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.nobut:hover {
  opacity: 0.38; }

.sibut {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  /* Rectangle Copy: */
  height: 50px;
  background-image: -webkit-gradient(linear, right top, left top, from(#C0D9B2), to(#04685C));
  background-image: linear-gradient(-90deg, #C0D9B2 0%, #04685C 100%);
  box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.16);
  border-radius: 100px;
  color: white; }

.sibut:hover {
  opacity: 0.88; }

.anticon svg {
  width: 100%;
  height: 100%; }

.mapblock {
  padding: 10px; }

.titleblock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0px auto;
  padding-top: 10px; }
  .titleblock svg {
    margin-right: 10px;
    font-size: 25px;
    color: gray; }
  .titleblock h4 {
    font-size: 12px; }

.indblock {
  margin-top: 1px;
  margin-bottom: 25px;
  padding: 0px 10px; }

/* -------------------------------- 
Typography
-------------------------------- */
:root {
  --font-primary: sans-serif;
  --font-secondary: serif;
  /* set base values */
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;
  /* type scale */
  --text-xs: calc(1em / (var(--text-scale-ratio) * var(--text-scale-ratio)));
  --text-sm: calc(1em / var(--text-scale-ratio));
  --text-md: calc(1em * var(--text-scale-ratio));
  --text-lg: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xxl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-xxxl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
  /* line-height */
  --heading-line-height: 1.2;
  --body-line-height: 1.4; }

/*@include breakpoint(md) {
    :root {
        --text-base-size: 1.25em;
        --text-scale-ratio: 1.25;
    }
}*/
body {
  font-size: 1em;
  font-size: var(--text-base-size);
  font-family: sans-serif;
  font-family: var(--font-primary);
  color: var(--color-text); }

h1, h2, h3, h4, form legend {
  color: var(--color-text-heading);
  margin-bottom: var(--space-xxs);
  line-height: 1.2;
  line-height: var(--heading-line-height); }

/* text size */
.text--xxxl {
  font-size: calc(1em * 1.2 * 1.2 * 1.2 * 1.2 * 1.2);
  font-size: var(--text-xxxl); }

h1, .text--xxl {
  font-size: calc(1em * 1.2 * 1.2 * 1.2 * 1.2);
  font-size: var(--text-xxl); }

h2, .text--xl {
  font-size: calc(1em * 1.2 * 1.2 * 1.2);
  font-size: var(--text-xl); }

h3, .text--lg {
  font-size: calc(1em * 1.2 * 1.2);
  font-size: var(--text-lg); }

h4, .text--md {
  font-size: 16px; }

.text--sm, small {
  font-size: calc(1em / 1.2);
  font-size: var(--text-sm); }

.text--xs {
  font-size: calc(1em / (1.2 * 1.2));
  font-size: var(--text-xs); }

p {
  line-height: 1.4;
  line-height: var(--body-line-height); }

a {
  color: var(--color-link); }
  a:visited {
    color: var(--color-link-visited); }

/* b, strong {
    font-weight: 600;
    font-size:24px;
}*/
.text-container h2, .text-container h3, .text-container h4 {
  margin-top: var(--space-sm); }

.text-container ul, .text-container ol, .text-container p {
  margin-bottom: var(--space-md); }

.text-container ul, .text-container ol {
  list-style-position: outside;
  padding-left: 24px; }

.text-container ul {
  list-style-type: disc; }

.text-container ol {
  list-style-type: decimal; }

.text-container ul li, .text-container ol li {
  line-height: 1.4;
  line-height: var(--body-line-height); }

.text-container em {
  font-style: italic; }

.text-container u {
  text-decoration: underline; }

/* utility classes */
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

