.imgtopcard{
  width:100%;
  height:170px;
  background:gainsboro;
}

.detailsbotcard{
  .name{
    padding: 10px 0px 0px 10px;
    width:100%;
    display:block;
    text-align:left;
    font-size:24px;
  }
  .location{
    padding: 0px 0px 0px 10px;
    width:100%;
    display:block;
    text-align:left;
    font-size:16px;
    color:gainsboro;
  }
  
}
.butbottom{
  transition: all 0.3s;
  .settings{
    border: 1px solid gainsboro;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .settings:hover{
    color: #0380b8;
  }
}

.barblock {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.barblock{
  padding:10px
  
}

.outherspeed{
  margin-top:30px;
}

.indicator{
  width: 90%;
  margin: 20px 0px;
  height: 70px;
  display: flex;
  align-items: center;
  margin-left:5%;
  
  h5{
      margin-bottom:1em;
  }
}
.BarWrapper{
  width: 90%;
  padding: 20px 0px 30px 0px;
  margin: 0px 5%;
  position: relative;
}
.barcontainer{
  height: 20px;
  background: #D8D8D8;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  transition: all 1s;
  
  .barindicator{
      height: 100%;
      position: absolute;
      z-index: 1;
      border-radius: 20px;
      transition: all 1s;
      transition-delay: .5s;
      background: gray;
  }
  .barobjective{
      height: 100%;
      position: absolute;
      z-index: 3;
      opacity: 0.25;
      filter: brightness(0.65) saturate(1.5);
      border-radius: 20px;
      transition-delay: .3s;
      transition: all 1s;
      background: gray;
  }
  .green{
      background-image: -webkit-linear-gradient(#B4EC51 0%, #429321 100%);
      background-image: -moz-linear-gradient(#B4EC51 0%, #429321 100%);
      background-image: -o-linear-gradient(#B4EC51 0%, #429321 100%);
      background-image: linear-gradient(#B4EC51 0%, #429321 100%);
  }
  .red{
      background-image: -webkit-linear-gradient(#F5515F 0%, #9F041B 100%);
      background-image: -moz-linear-gradient(#F5515F 0%, #9F041B 100%);
      background-image: -o-linear-gradient(#F5515F 0%, #9F041B 100%);
      background-image: linear-gradient(#F5515F 0%, #9F041B 100%);
      }
  .orange{
      background-image: -webkit-linear-gradient(#FAD961 0%, #F76B1C 100%);
      background-image: -moz-linear-gradient(#FAD961 0%, #F76B1C 100%);
      background-image: -o-linear-gradient(#FAD961 0%, #F76B1C 100%);
      background-image: linear-gradient(#FAD961 0%, #F76B1C 100%);
      }
}
.bararrow{
  display: block;
  width: 50px;
  height: 30px;
  font-size: 10px;
  line-height: 0px;
  position: absolute;
  opacity: 0.8;
  margin-left: -25px;
  transition: all 2s;
  transition-delay: 1.3s;
}

.objetiv{
  top: 0px;
  opacity: 0.4;
}

.etiqetainterior{
  color:white;
  position: absolute;
  z-index: 3;
  font-size:10px;
  line-height:15px;
  left:10px;
  width: 90%;
  height: 100%;
}