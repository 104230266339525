.authPage {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 0px 0;
  background: #FAFAFB;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
}

.wrapper {
  height: 221px;
  width: 685px;
  text-align: center;
  /* background-image: url('../../images/background_empty.svg'); */
  background-position-x: center;
  font-size: 14px;
}

.errorsContainer {
  margin-top: -21px;
  margin-bottom: 18px;
  color: #ff203c;
}

.headerContainer {
  line-height: 36px;
  font-size: 24px;
  font-weight: 600;
}

.headerContainer img {
  /*margin-top: 1px;*/
  height: 40px;
}
.headerDescription {
  width: 416px;
  text-align: center;
  margin: auto;
  padding: 13px 30px 0 30px;
  line-height: 18px;
  color: #333740;
  font-size: 14px;
}

.formContainer {
  min-height: 200px;
  width: 416px;
  margin: 14px auto;
  margin-bottom: 0;
  padding: 33px 15px 15px 15px;
  border-radius: 2px;
  background-color: #FFFFFF;
  border-radius:20px;
  box-shadow: 0 2px 20px rgba(0,0,0,0.2)
}

.formContainer input{
  width: calc(100% - 10px);
}

.loginButton {
  margin-top: -6px;
  padding-right: 0;
  text-align: right;
}

.loginButton, button {
  margin-right: 16px;
}

.buttonContainer {
  padding-top: 3px;
}

/* .linkContainer {
  padding-top: 18px;
   > a {
    color: #262931;
    font-size: 13px;
    &:hover, &:active, &:focus {
      text-decoration: none;
      outline: 0;
    }
  }
} */

.linkContainer, a {
  color: #262931;
  font-size: 13px;
}

.logoContainer {
  position: absolute;
  left: 30px; bottom: 30px;
}

.logoContainer img {
  height: 34px;
}

.separator {
  width: 100%;
  height: 2px;
  margin: 10px 0;
  background-color: rgba(14, 22, 34, 0.04);
}
.accede {
 background-color: #088A68;
 background-image: linear-gradient(-90deg, #088A68, #D8F6CE);
 color: white;
 border-radius: 15px;
 border-style: none;
 height: 3.5em;
}
.identi {
 color: #000000;
 font-family: sans-serif;
 font-weight: bold;
}
.userimg {
  height: 300%;
  
}
.sans {
  font-family: sans-serif;
}
.textoacceso {
  font-family: sans-serif;
  text-align: center;
  font-size: 0.9rem;
}
.sombra {
  box-shadow: 0 8px 10px 0 #b4cbf1;
}

/*body{background: url(../../assets/images/bg.jpg) cover;}*/
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.head{
  background: white;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.3);
}

.dropzoneCleaner > div{
  height: auto !important;
  border: none !important;
  margin: 0 auto;
}

.rst__rowContents {
  border: 0 !important;
}
  
.rst__rowContentsDragDisabled {
  border: 0 !important;
  box-shadow: initial !important;
  padding: 0 !important;
  min-width: 0 !important;
  background-color: transparent !important;
  align-items: flex-end !important;
}