.titleblock{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0px auto;
    padding-top:10px;
    svg{
        margin-right: 10px;
        font-size:25px;
        color: gray;
        // flex:1
    }
    h4{
        // flex:8;
        font-size:12px
    }
}

.indblock{
    margin-top: 1px;
    margin-bottom: 25px;
    padding:0px 10px
}