.rankingElement{
    display:flex;
    align-items: center;
    justify-content: center;
    border-bottom:1px solid gainsboro;
    height:70px;
    .itemImage{
        display:flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        flex:1.5;
        padding:0 10px;
        img{
            width:50px;
            height:50px;
            border-radius:100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .itemTitle{
        flex:4;
        display:flex;
        // align-items: center;
        // justify-content: center;
        padding:0 15px;
        text-align: left;

    }
    .itemBar{
        flex:9;
        // display:flex;
        align-items: center;
        justify-content: center;
        // padding:15px;

    }
    .itemValue{
        flex:1.5;
        display:flex;
        align-items: center;
        justify-content: center;
        padding:0 10px;
        span{
            font-size: 2.2rem;
            font-weight: 800
        }
    }
}

.rankingElement:nth-child(even){
    background-color: #f3f3f3
}
.rankingElement:last-child{
    border-bottom:0px solid gainsboro;
}