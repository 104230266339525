@import url('https://fonts.googleapis.com/css?family=Poppins:400,600,700');

*{
font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue;
  /*background: #FAFAFA;*/
  /*background:url(./assets/bgmax.jpg);*/
  background-size:cover;
}


input:focus {
  border-color: #78caff;
  outline: 0;
  box-shadow: none !important;
}

.centerall{
    display:flex;
    align-items:center;
    justify-content: center;
}

.grisradius{
  border: 1px solid gainsboro;
  border-radius: 5
}

.finalizaActivas .rt-td{
  margin: auto;
}