.bgfiltro{
    height:80px;
    background-image: linear-gradient(#52977F, #277E6C);
    background-size: cover;
    background-position: center;
    color:white;
    h1{
        margin:0px;
        text-align: left;
        padding-left: 50px;
    }
}
.innerFiltro{
    display: flex;
    justify-content: space-between;
}
.containerFiltro{
    display: flex;
}
.tituloblock{
    padding:15px;
}
.fechablock{
    flex:3;
    padding:15px;
    justify-content: flex-end;
    display: flex;
    .DateRangePickerInput__withBorder{
        min-width:310px
    }
}
.centroblock{
    flex:2;
    padding:15px;
    .Dropdown-root{
        height:50px;
    }
    .Dropdown-control{
        height:50px;
    }
    .Dropdown-placeholder{
        line-height: 30px;
    }
    .Dropdown-arrow{
        top:23px
    }
    .Dropdown-option{
        padding:15px 10px;
    }
}
.breadcrumbs span{
    white-space: nowrap;
}