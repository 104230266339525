.indicator{
    position:absolute;
    top:-30px;
    right:0px;
    width:40px;
    height:40px;
    border-radius:20px;
    color:white;
    display:flex;
    align-items: center;
    justify-content: center;
}
.red{
    background-color: red;
}
.green{
    background-color: green;
}

.mainBlock{
    height: 460px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2em;
    .imageTeam{
        width:100%;
        height:150px;
        overflow:hidden;
        display:flex;
        border-radius: 25px 25px 0px 0px;
        img{
            width:100%;
            height:100%;
            object-fit: cover;
        }
    }
    .teamBlock{
        padding: 0 15px;
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        h3{
            margin-bottom: 0px;
            text-decoration: none;
        }
        h5{
            margin-top: 0px;
            margin-bottom: 0px;
            text-decoration: none;
            color:rgb(136, 148, 156)
        }
    }
    .indicadoresBlock{
        padding: 0 15px;
        margin-bottom:10px;
    }
    .buttonBlock{
        display:flex;
        border-top:2px solid gainsboro;
        .buttoncard{
            flex:1;
            height:50px;
            align-items: center;
            justify-content: center;
            display: flex;
            font-size:25px;
            color:gray;
            transition: 0.3s all;
            cursor: pointer;
        }
        .buttoncard:hover{
            color:black;
            transition: 0.3s all
        }
        .buttoncard:first-child{
            border-right:2px solid gainsboro;
        }
    }
}