.CircularProgressbar-path       { stroke: red; }
.CircularProgressbar-trail      { stroke: #f3f3f3; }
.CircularProgressbar-text       { fill: yellow;  }
.CircularProgressbar-background { fill: green; }

.card{
    background: white;
    border-radius: 20px;
    box-shadow: 0 5px 10px 2px rgba(200, 200, 200, 0.3);
}
.titleblock{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    min-height: 2em;
    margin: 0px auto;
    padding:10px 0;
    svg{
        margin-right: 10px;
        font-size:25px;
        color: gray;
        min-width:25px;
        // flex:1
    }
    h4{
        // flex:8;
        font-size:12px
    }
}

.progresscontainer{
    display:flex;
    align-items: center;
    justify-content: center;
    margin:15px 0px;
}

.chartblock{
    display:flex;
    align-items: center;
    justify-content: center;
    padding:30px 0px;
    
}