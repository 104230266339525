.fichatitleblock{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align:center;
    height:200px;
    position:relative;
    h3{
        font-size:25px;
        margin:0px 0px;
        color:#5A5970;
        margin-top:-10px;
    }
    small{
        font-size:16px;
        color:gainsboro;
    }
    h5{
        font-size:20px;
        color:#ABA9B1;
        margin:0px 0px
    }
    .btnActionWrapper{
        display: flex;
        position: absolute;
        bottom: -25px;
        width: 100%;
        justify-content: space-evenly;
    }
    .botonficha{
        height:45px;
        width:200px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: gray;
        box-shadow: 0px 4px 15px rgba(0,0,0,0.1);
        border-radius: 25px;
        color:white;
        // margin:0px auto;
        // position: absolute;
        // bottom:-25px;
        // left: calc(50% - 100px) ;
        cursor: pointer;
        transition: all 0.5s;
    }
    .botonficha:hover{
        background-color: #5A5970;
        transition: all 0.5s;
    }

}
.fichapictureblock{
    height:300px;
    width:100%;
    border-radius: 25px 25px 0px 0px;
    overflow:hidden;
    img{
        object-fit: cover;
        object-position: center
    }
}