.iconplot {

  background-image: linear-gradient(-90deg, #C0D9B2 0%, #04685C 100%);
  box-shadow: 0 10px 20px 0 rgba(74, 74, 74, 0.26);
  border-radius: 3px;
  max-width: 45px;
  width:45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radius {
  border-radius: 18px;
}

.table {
  // height:400px;
  width: 100%;
  margin-bottom: 0;
}

.rt-th {
  border: 0px !important;
  font-weight: 700;
  color: gray;
}

.bottonera {
  display: flex;
  flex-direction: row;
  border: 1px solid #DEE1E6;
  border-radius: 100px;
  height: 45px;
  width: auto;
  align-items: center;
  justify-content: space-evenly;
}

.boton {
  font-size: 12px;
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.buticon {
  cursor: pointer;
}

.buticon:hover {
  opacity: 0.8;
}

.activo {
  background-image: linear-gradient(-90deg, #C0D9B2 0%, #04685C 100%);
  box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.16);
  border-radius: 100px;
  padding: 10px 10px;
  color: white;
  font-weight: 700;
  transition: filter 300ms;
  cursor: default;
}

.activo:hover {
  filter: brightness(0.9) saturate(1.1)
}

.table {
  overflow: hidden;
  border-radius: 19px;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: hidden;
  height: 55px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ReactTable .rt-tbody .rt-tr-group {
  height: 65px;

}

.cabecera {
  display: flex;
  flex-direction: row;
  height: 75px;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid gainsboro;
  .comp {
    color: gainsboro;
    font-weight: 700;
    font-size: 16px;
  }
}

.megarow {
  padding-right:10px;
  height: 70px;
  border-bottom: 1px solid gainsboro;
  display: flex;
  align-items: center;
  justify-content: space-evenly
}

.megarow:nth-child(even) {
  background-color: #F2F2F2;
}

.avatar {
  width: 50px !important;
  height: 50px !important;
}

.avatarbig {
  width: 70px !important;
  height: 70px !important;
}

.comp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 12px;
  overflow: hidden;
  text-align: center;
}

.meganum {
  font-size: 40px;
  font-weight: 700;
  color: gray;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}

.leftpad {
  p {
    margin-bottom: 0;
  }
}

.navbar-brand img {
  width: 150px;
  height: auto;
}

.nombreavatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h5 {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 24px;
  }
  p {
    margin-bottom: 0px;
  }
}

.Modal {
  border-radius: 24px;
  width: 80%;
  height: 500px;
  background-color: white;
  z-index: 5;
  overflow-Y: overlay;
}

.inputer {
  /* Rectangle 8 Copy 20: */
  background: #FFFFFF;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.23);
  border-radius: 7px;
  height: 45px;
  width: 100%;
  border: 0px;
  padding: 0px 10px;
}

input.inputer:focus {
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.23);
}

.selecthor {
  background: #FFFFFF !important;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.23) !important;
  border-radius: 7px !important;
  height: 45px !important;
}

.css-1aya2g8 {
  height: 45px;
  border: 0px !important;

}

.css-1rtrksz {
  border: 0px !important;
  height: 45px !important;
}

.nobut {
  opacity: 0.58;
  background-image: linear-gradient(-90deg, #E5E5E5 0%, #939393 100%);
  box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.16);
  border-radius: 100px;
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nobut:hover {
  opacity: 0.38;
}

.sibut {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  /* Rectangle Copy: */
  height: 50px;
  background-image: linear-gradient(-90deg, #C0D9B2 0%, #04685C 100%);
  box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.16);
  border-radius: 100px;
  color: white;
}

.sibut:hover {
  opacity: 0.88;
}

.anticon svg{
  width: 100%;
  height: 100%;
}

