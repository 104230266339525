.loader{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1257890;
    background: white;
    transition: all 600ms;
    opacity: 0.99;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.loader div, .loader span{
    margin: 0 3px 0 5px;
    animation-name: punto;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    color: white;
    text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15)
}
.loader h1{
    color: white;
    text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15)
}
.loader:not(.loading){
    opacity: 0;
    z-index: -1;
    overflow: initial;
}

#punto1{
    animation-delay: 0ms;
}
#punto2{
    animation-delay: 500ms;
}
#punto3{
    animation-delay: 1000ms;
}

@keyframes punto {
    0%   { opacity: 0; margin-top:  5px }
    50%  { opacity: 1; margin-top: -5px }
    100% { opacity: 0; margin-top:  5px }
}
